.updates {
  height: 22px;
  width: 100%;
  display: flex;
  /* background-color: #67E0A6; */
  background-color: #ddd4ff;
  position: relative;
  overflow: hidden;
  align-items: center;
}
.updatesleft {
  width: 10%;
  color: white;
  font-weight: bold;
  font-family: sans-serif;
  background-color: #cc2121;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  z-index: 2;
  clip-path: polygon(
    27.1% 0%,
    51.4% 0%,
    79% 3%,
    100% 50%,
    100% 50%,
    76.8% 100%,
    79% 100%,
    65.7% 100%,
    0% 100%,
    0% 0%
  );
}
.updatesright {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  font-weight: bold;
  position: absolute;
  width: 90%;
}
.upinner {
  position: absolute;
  height: 100%;
  display: flex;
  right: -20%;
  animation: mymove 15s linear infinite;
  z-index: 1;
}
.upinner a {
  text-decoration: none;
  color: #0f2b4c;
  font-weight: bold;
  font-family: sans-serif;
  font-size: 12px;
  margin-top: 2px;
  margin: 0px 10px;
}

@keyframes mymove {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-400%);
  }
}

.toptop {
  display: flex;
}

.menuimageicon {
  display: none;

  /* display: flex; */
  align-items: center;
  justify-content: center;
  width: 20%;
  font-size: 30px;
}

.tonavbar1 {
  /* border: 2px solid red; */
  height: 150px;

  width: 100%;
  background-color: #111a5c;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px;
  font-family: "Ascender Serif";
}
.tonavbar1 img {
  /* border: 2px solid red; */
  width: 190px;
  height: 150px;
  margin-right: 2rem;
}

.imp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px 0px;
}

@media screen and (max-width:874px){
  .imp{
    /* border: 2px solid red; */
    margin: 0;
    padding: 0;
  }
  .imp>h1{
    font-size: 1.7rem;
  }
}

@media screen and (max-width:768px) {
  .imp{
    /* border: 2px solid red; */
    margin: 0;
    padding:0;
  }
  .imp>h1{
    font-size: 1.3rem;
  }
}

@media screen and (max-width:600px) {
  .imp{
    /* border: 2px solid red; */
    margin: 0;
    padding: 0;
  }
  .imp>h1{
    font-size: 1.2rem;
  }

  .imp>p{
    font-size: .9rem;
    
  }

  /* .imp > p {
    display: none;
} */
}
@media screen and (max-width:500px) {
  .imp{
    /* border: 2px solid red; */
    margin: 0;
    padding: 0;
  }
  .imp>h1{
    font-size: 1.1rem;
  }
  /* .imp{
    line-height: 13px;
  } */
}
@media screen and (max-width: 768px) {
  .updates {
    height: 20px;
  }
  .updatesleft {
    width: 10%;
    height: 100%;
    font-size: 10px;
  }
  .upinner a {
    font-size: 10px;
  }

  .toptop {
    display: flex;
  }

  .menuimageicon {
    /* display: none; */
    background-color: #111a5c;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    font-size: 30px;
    color: white;
  }

  .tonavbar1 {
    height: 120px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 0px 20px;
    padding: 13px;
    gap: 1rem;
  }
  .tonavbar1 img {
    width: 100px;
    height: 100px;
    margin-right: 0;
  }
}



@media screen and (max-width: 600px) {
  .updates {
    height: 20px;
  }
  .updatesleft {
    width: 12%;
    height: 100%;
    font-size: 10px;
  }
  .tonavbar1 img {
    width: 100px;
    height: 100px;
    margin: 0;
  }
}

@media screen and (max-width: 500px) {
  .updates {
    height: 20px;
  }
  .updatesleft {
    width: 14%;
    height: 100%;
    font-size: 10px;
  }
}

@media screen and (max-width: 400px) {
  .updates {
    height: 20px;
  }
  .updatesleft {
    width: 18%;
    height: 100%;
    font-size: 10px;
  }
  .menuimageicon {
    /* display: none; */
    width: 20%;
    font-size: 23px;
    padding-right: 10px;
  }
}
@media screen and (max-width: 500px) {
  .tonavbar1 img {
    width: 25%;
    height: 90%;
  }
}

@media screen and (max-width: 380px) {
  .tonavbar1 img {
    width: 70px;
    height: 70px;
  }
}
